var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showExternalJobsModal ? _c('r-modal', {
    attrs: {
      "title": _vm.$t('admin.externalJobs.title')
    },
    on: {
      "close": _vm.onClose
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('r-error-message', {
          attrs: {
            "errors": [_vm.fetchExternalJobsError]
          }
        }), _c('div', {
          staticClass: "mt-3"
        }, [_c('r-table', {
          attrs: {
            "rows": _vm.externalJobs,
            "total-rows": _vm.externalJobsCount,
            "columns": _vm.tableColumns,
            "loading": _vm.fetchingExternalJobs,
            "page": _vm.page,
            "page-size": _vm.pageSize,
            "is-selectable": false
          },
          on: {
            "change:page": _vm.onChangePage,
            "change:page-size": _vm.onChangePageSize,
            "sort:asc": function sortAsc(_ref) {
              var value = _ref.value;
              return _vm.onChangeSort({
                sort: value,
                sortDir: 'asc'
              });
            },
            "sort:desc": function sortDesc(_ref2) {
              var value = _ref2.value;
              return _vm.onChangeSort({
                sort: value,
                sortDir: 'desc'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "item.requestedDate",
            fn: function fn(_ref3) {
              var item = _ref3.item;
              return [_c('span', [_vm._v(_vm._s(_vm.formatDate(item.requestedDate)))])];
            }
          }, {
            key: "item.status",
            fn: function fn(_ref4) {
              var item = _ref4.item;
              return [_c('r-badge', {
                attrs: {
                  "name": item.status,
                  "color": _vm.getStatusColor(item.status)
                }
              })];
            }
          }], null, false, 2745822551)
        })], 1)];
      },
      proxy: true
    }, {
      key: "customActions",
      fn: function fn() {
        return [_c('r-button', {
          staticClass: "close-btn",
          attrs: {
            "outlined": true,
            "label": _vm.$t('global.actions.cancel')
          },
          on: {
            "click": _vm.onClose
          }
        })];
      },
      proxy: true
    }], null, false, 3729272571)
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }