var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "urlDetails pa-8"
  }, [_vm.fetchUrlDetailsError ? _c('r-error-message', {
    attrs: {
      "errors": [_vm.fetchUrlDetailsError]
    }
  }) : _vm._e(), !_vm.fetchingUrlDetails && !_vm.fetchUrlDetailsError ? _c('div', [_c('r-page-header', {
    staticClass: "urlDetails--header",
    attrs: {
      "previousroute": "/admin/urls",
      "label": _vm.urlDetails.url
    },
    scopedSlots: _vm._u([{
      key: "postlabel",
      fn: function fn() {
        return [_c('a', {
          attrs: {
            "href": _vm.urlDetails.url,
            "title": "Open the url in the target site",
            "target": "_blank",
            "rel": "noreferrer"
          }
        }, [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "black"
          }
        }, [_vm._v(" open_in_new ")])], 1)];
      },
      proxy: true
    }], null, false, 1608785359)
  }), _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Name: ")]), _c('p', {
    staticClass: "urldetails--value text-truncate",
    attrs: {
      "title": _vm.urlDetails.name
    }
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.name) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Number of Reviews: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.numReviews) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Last Reviewed: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.urlDetails.lastReviewed)) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Average Rating: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.averageRating) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_c('span', [_vm._v("Scrape Status:")])]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.formatStatus(_vm.urlDetails.scrapeJob.status)) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Scrape Failure Count: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.scrapeJob.failureCount) + " ")])]), _c('v-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Scrape Retry Count: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.scrapeJob.retryCount) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Number of Reviews Last Scraped: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.urlDetails.scrapeJob.numReviewsLastScrape) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Date Last Found Reviews: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate(_vm.urlDetails.scrapeJob.dateLastFoundReviews)) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Scrape Time: ")]), _c('p', {
    staticClass: "urldetails--value"
  }, [_vm._v(" " + _vm._s(_vm.formatUpdateSlot(_vm.urlDetails.scrapeJob.updateSlot)) + " ")]), _c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Non Canonical URLs: ")]), _vm._l(_vm.urlDetails.nonCanonicalUrls, function (url, index) {
    return _c('p', {
      key: index,
      staticClass: "urldetails--value d-block"
    }, [_vm._v(" " + _vm._s(url) + " ")]);
  })], 2)], 1), _c('v-row', [_c('v-col', {
    staticClass: "pt-0"
  }, [_c('p', {
    staticClass: "urldetails--property"
  }, [_vm._v(" Clients: ")]), _c('v-data-table', {
    staticClass: "elevation-1",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.urlDetails.clients,
      "hide-default-footer": ""
    }
  })], 1)], 1), _vm.showReScrapeButton ? _c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "label": "Re-scrape",
      "disabled": _vm.isReScrapeDisabled
    },
    on: {
      "click": _vm.retriggerScrapping
    }
  }), _vm.useExternalDataProvider ? _c('r-button', {
    attrs: {
      "outlined": "",
      "disabled": !_vm.useExternalDataProvider,
      "label": "View external jobs"
    },
    on: {
      "click": function click($event) {
        _vm.showExternalJobsModal = true;
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.urlId ? _c('external-jobs-modal', {
    attrs: {
      "url-id": _vm.urlId,
      "show-external-jobs-modal": _vm.showExternalJobsModal
    },
    on: {
      "close:external-jobs-modal": function closeExternalJobsModal($event) {
        _vm.showExternalJobsModal = false;
      }
    }
  }) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }