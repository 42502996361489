<template>
  <div>
    <r-modal
      v-if="showExternalJobsModal"
      :title="$t('admin.externalJobs.title')"
      @close="onClose"
    >
      <template v-slot:content>
        <r-error-message :errors="[fetchExternalJobsError]" />
        <div class="mt-3">
          <r-table
            :rows="externalJobs"
            :total-rows="externalJobsCount"
            :columns="tableColumns"
            :loading="fetchingExternalJobs"
            :page="page"
            :page-size="pageSize"
            :is-selectable="false"
            @change:page="onChangePage"
            @change:page-size="onChangePageSize"
            @sort:asc="({ value }) => onChangeSort({ sort: value, sortDir: 'asc' })"
            @sort:desc="({ value }) => onChangeSort({ sort: value, sortDir: 'desc' })"
          >
            <template v-slot:item.requestedDate="{ item }">
              <span>{{ formatDate(item.requestedDate) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
              <r-badge
                :name="item.status"
                :color="getStatusColor(item.status)"
              />
            </template>
          </r-table>
        </div>
      </template>
      <template v-slot:customActions>
        <r-button
          :outlined="true"
          class="close-btn"
          :label="$t('global.actions.cancel')"
          @click="onClose"
        />
      </template>
    </r-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { COLORS } from '@/utils/constants'

import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'

import RTable from '@/components/library/organisms/RTable'
import RModal from '@/components/library/organisms/RModal'
import RButton from '@/components/library/atoms/RButton'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RBadge from '@/components/library/atoms/RBadge'

const STATUS_TO_COLOR = {
  purged: COLORS.GREY,
  complete: COLORS.GREEN,
  invalid_url: COLORS.RED,
  maintenance: COLORS.ORANGE,
  failed: COLORS.RED,
}

export default {
  name: 'ExternalJobsModal',
  components: {
    RBadge,
    RTable,
    RButton,
    RModal,
    RErrorMessage,
  },
  props: {
    showExternalJobsModal: {
      type: Boolean,
      default: false,
    },
    urlId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tableColumns: [
      {
        key: 'jobId',
        label: 'Job Id',
        width: 100,
      },
      {
        key: 'requestedDate',
        label: 'Requested date',
        sortable: true,
        width: 150,
      },
      {
        key: 'status',
        label: 'Job Status',
        sortable: false,
        width: 150,
      },
    ],
    page: 1,
    pageSize: 10,
    sort: 'requestedDate',
    sortDir: 'desc',
  }),
  computed: {
    ...mapState('admin', [
      'fetchingExternalJobs',
      'fetchExternalJobsError',
      'externalJobsCount',
      'externalJobs',
    ]),
  },
  watch: {
    showExternalJobsModal(newVal) {
      if (newVal) {
        this.fetchData()
      }
    },
  },
  methods: {
    ...mapActions('admin', [
      'fetchExternalJobs',
    ]),
    formatDate(date) {
      if (!date) {
        return ''
      }

      return formatDate({
        format: 'DD/MM/YYYY HH:mm:ss',
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onClose() {
      this.page = 1
      this.pageSize = 10
      this.sort = 'requestedDate'
      this.sortDir = 'desc'

      this.$emit('close:external-jobs-modal')
    },
    fetchData() {
      const fetchParams = {
        urlId: this.urlId,
        page: this.page,
        pageSize: this.pageSize,
        sort: this.sort,
        sortDir: this.sortDir,
      }

      this.fetchExternalJobs(fetchParams)
    },
    onChangePage({ value }) {
      this.page = value
      this.fetchData()
    },
    onChangePageSize({ value }) {
      this.pageSize = value
      this.fetchData()
    },
    onChangeSort({ sort, sortDir }) {
      this.page = 1
      this.sort = sort
      this.sortDir = sortDir
      this.fetchData()
    },
    getStatusColor(status) {
      return STATUS_TO_COLOR[status] || COLORS.GREY
    },
  },
}
</script>
